.dashboard{
   display: flex;

    .dashboardContainer{ 
      flex: 8;
      width:100vw;
      overflow: auto ;
      background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95)) ;
      background-repeat: no-repeat;      //background-size:auto;
      background-position:center;
      background-blend-mode:soft-light;

      .mainContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden ;                     //justify-content: center;  //margin: 0 auto;  //margin-bottom: 60ch;
            
        .widgets, .charts {
          display: flex;                   //flex: 1;
          justify-content: space-between;  // min-width: 400; //gap:2em;  // padding: .4em;  // width: auto;  // border: 10px solid red;
          width:100%;
        }     

        .graphContainer {
          display:flex;
          flex-direction: row;             //flex:5;              // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);              // box-shadow: 2px 3px 7px 1px rgba(201, 201, 201, 0.47);
          padding: 2px;
          width:'100%';
          height:'105ch';
          align-items:flex-start;
          justify-content:flex-start;
          border: 1px solid  blue;              
        }  
                        
        .listTitle{
          display:flex;
          padding: 1px;              //width:99.5%;
          align-items:center;
          justify-content:center;
          width:'99.5%',
        }
      }
    }
}

.row {
  display: flex;            
  align-items: flex-start;            //  justify-content: space-evenly;
  //flex-direction: column;               //margin: 3px;  //background-color: rgba(228, 250, 228, 0.436);
  overflow:auto;
  background-image: url('../../resource/images/juxaroo.jpg'), 
    linear-gradient(to bottom,rgba(244, 251, 244, 0.96), rgba(225, 236, 225, 0.89)) ;
  background-repeat: no-repeat;
  background-size:cover;
  background-position:center;
  background-blend-mode:color-dodge;
  //margin-top: 30px;
  height:95vh;
  gap:2ch;
  // background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgb(244, 251, 244), rgba(220, 240, 223, 0.823)), ;
  //background-size: cover;

  // background-position: bottom right;
  // background-blend-mode:soft-light;
  // background-blend-mode:color-dodge;

  //height: 31ch;
  // -webkit-box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.47);
  // box-shadow: 0 0 3px 2px rgba(201, 201, 201, 0.47);
  // background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgb(244, 251, 244), rgba(220, 240, 223, 0.823)), ;
  // background-repeat: no-repeat;
  // //background-size: cover;
  // background-position: bottom right;
  // background-blend-mode:soft-light;
   //  background-blend-mode:color-dodge;
  //margin-top: 5px;  // width:100%; //defaults to parents container which is paper in this case
  //gap:5px; //border: 1px solid red; //flex: 1;
  
  .circularbarContainer{
      display:flex;
      flex-direction: row;
      //flex:3;
      // background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgb(244, 251, 244), rgba(220, 240, 223, 0.823)), ;
      // background-repeat: no-repeat;
      // background-size: cover;
      // background-position: bottom right;
      // background-blend-mode:soft-light;
      // background-blend-mode:color-dodge;
      // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      // box-shadow: 0 0 3px 1px rgba(201, 201, 201, 0.47);
      // align-items: flex-start;
      //justify-content:space-evenly;
      //border: 1px solid lightgray;
      //min-height:20vh;
      //height: 30ch;
      gap:1ch;
    
       //width:0ch;
      //height:50%;
      //max-width:20vw;
      //min-width:100%;
      //border: 4px solid lightgray;
      //margin:20px;
      padding:10px;

    .circulatbarGroup{
        display: flex;
        //flex-direction: row;
        justify-content:flex-start;
        align-items:center;

        ///width: 57ch;
        //padding: 5px;
        //min-width:15%;
        //height:45ch;
        //font-size: 1em;
        font-family: Arial, Helvetica, sans-serif; 
        //gap:3ch;
        //padding:2px;
        //margin-bottom: 20;
        //min-height: 2.5em;
        //border:1px solid blueviolet;
        //margin:100;              

      .circularBar{
          display: flex;
          // -webkit-box-shadow:  1px 1px 2px 1px lightgray;
          // box-shadow: 1px 1px 3px 1px lightgray;
          flex-direction: column;
          align-items: center;
          width:20ch;
          justify-content: space-evenly;                       //font-size: 1em;
          font-family: Arial, Helvetica, sans-serif;               //width:90%;                       //height:20%;
          font-weight: 600;
          //font-size: 19px;
          padding: 2px;
          //gap:1ch;                            //  border:1px solid rgb(223, 41, 41);
          //border: 1px solid lightgray;
          transition: all 1s ease;
          &:hover {
            z-index: 3;
            //background-color: #e9f5f5;
            transform: scale(1.1, 1.1);
            box-shadow: 2px 2px 3px 1px lightgray;
            color:red
            //transform: translateX(5em);
          //  transform: rotate(360deg);
             //matrix(scaleX(), skewY(), skewX(), scaleY(), translateX(), translateY());
            // height:200px;
          }

          .cursive{
            font-family: Arial, Helvetica, sans-serif; ;
            font-weight: 400;
            //font-size: 10pt;
          }
      }    
    }    // circularbar END



  }  // circularbarContainer END
} //row END
