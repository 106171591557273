// .new{
//     display: flex;
//     //flex-direction: row;

//     .newContainer{

//         // // -webkit-box-shadow: 0 0 2px 2px gray;
//         // // box-shadow: 0 0 2px 2px gray;
//         flex: 8;
//         width:100hw;
//         overflow: hidden ;
//         background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95)) ;
//         background-repeat: no-repeat;
//         //background-size:auto;
//         background-position:bottom right;
//         background-blend-mode:soft-light;
    
    
        .newWrapper{
            display:flex;
            // justify-content: flex-start;
            align-content: flex-start;  
            // align-items:center;
             justify-content: space-between;    
            overflow: auto;
            background-image: url('../../resource/images/juxaroo.jpg'),
            linear-gradient(to bottom,rgba(244, 251, 244, 0.96), rgba(225, 236, 225, 0.89)) ;
            background-repeat: no-repeat;
            background-size:cover;
            background-position:center;
            background-blend-mode:color-dodge;
            //height:100%;
            //padding:10px;
            width: 100vw;
            height: 100vh;
    
            // -webkit-box-shadow: 0px 0px 2px  #897f7fb1; 
            // box-shadow: 0px px 2px 2px #897f7fb1;            
            // min-height:100vh;
            // width: 100hw;
            // margin-left:2px;
            // margin-top: 3px;
            // min-width:80%;
            // height: 100%;
            // background: linear-gradient(0.25turn, #3f87a622, #ebf8e126, #f69c3c1d);
             //border: 5px solid blue;
            // linear-gradient(to bottom right,rgba(255, 0, 0, 0.32), rgba(255, 166, 0, 0.32), rgba(255, 255, 0, 0.34), rgba(0, 128, 0, 0.32), rgba(76, 0, 130, 0.374), rgba(238, 130, 238, 0.42));

            // background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgb(244, 251, 244), rgba(220, 240, 223, 0.833)), ;
            // background-repeat: no-repeat;
            // //background-size: auto;
            // background-position:bottom right;
            // background-blend-mode:soft-light;
            // opacity:85%;


            .newItem{
                display:flex;
                justify-content: flex-start;
                align-content: flex-start;      
                //box-shadow: 0 0 2px 0px gray; 
                //margin-left: 2ch;
                //min-height:100vh;
                //margin-top: 2ch;
                width: 90vw;
                height:100vh;
                overflow: auto;
                padding:5px;

                //width: 230ch;
                //height:auto;
                //color:rgba(28, 28, 121, 0.71);
                //cursor:pointer;
            }
                // .newClient{
                //     display:flex;
                //     justify-content: center;
                //     align-items:flex-start;
                //     margin: 0px;
                //     font-weight: 800;
                //     color:rgba(28, 28, 121, 0.71);
                //     // min-height: 100%;
                //     // min-height: 100%;
                //     //cursor:pointer;

                // }
                // .newJob{
                //     display:flex;
                //     justify-content: center;
                //     align-items:flex-start;
                //     margin: 0px;
                //     font-weight: 800;
                //     color:rgba(28, 28, 121, 0.71);
                //     //cursor:pointer;
                // }
            // .newUser{
            //     display:flex;
            //     justify-content: center;
            //     align-items:flex-start;
            //     margin: 0px;
            //     font-weight: 800;
            //     color:rgba(28, 28, 121, 0.71);
            //     //width: 100%;
            //     // cursor:pointer;
            // }
            // .newUnknown{
            //     display:flex;
            //     justify-content: center;
            //     align-items:flex-start;
            //     //margin-top: 1px;
            //     font-weight: 800;
            //     color:blue;
            //     //width: 90%;
            // }
            // .Btn {
            //     width: 170px;
            //     // height: 5ex;
            //     box-shadow: 6px 4px 8px rgba(0, 0, 0, 0.315);
            //     background-image: linear-gradient(115deg, #9d667662 40%, #a96c4b9b);
            //     // border: none;
            //     border-radius: 5px;
            //     font-weight: 800;
            //     color: success;
            //     cursor: pointer;
            // }
            //     .Btn:active {
            //         box-shadow: 10px 10px 8px  rgba(0, 0, 0, 0.17);
            // }

            // .Btn1 {
                
            //     color: green;
               
            // }
            //     .Btn1:active {
            //         box-shadow: 10px 10px 8px  rgba(0, 0, 0, 0.17);
            // }

        }
//     }
// }