// .sideBar{
//     display: flex;

//     .navBar{
//         flex: 8;
//         // align-items: center;
//         // justify-content: space-between;
//         width:100hw;
//         overflow: hidden ;
//         background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95)) ;
//         background-repeat: no-repeat;
//         //background-size:auto;
//         background-position:bottom right;
//         //background-blend-mode:soft-light;
//         background-blend-mode:lighten;  

        .profileScreen{            
            display:flex;
            align-items:flex-start;
            justify-content: flex-start;    
            overflow: auto;
            background-image: url('../../resource/images/juxaroo.jpg'), linear-gradient(to bottom,rgba(244, 251, 244, 0.96), rgba(225, 236, 225, 0.89)) ;
            background-repeat: no-repeat;
            background-size:cover;
            background-position:center;
            background-blend-mode:color-dodge;
            height:100vh;            //padding:40px;             //border: 1px solid blue;
                
            .itemTable{
                display: flex;
                flex-direction: column;
                align-items: center;                
                justify-content: center; //margin: 5ch;
                overflow:  auto;   //height: auto;
                width:clamp(300px, 70%, 1100px);   //padding-top:3.5ch; 
                padding: 20px; //border: 1px solid red; //box-shadow: 0 0 1px 1px gray;//width:fit-content;//width: 80%;//height: 40ch; //margin-top: 5ch;// margin-left: 10px;//height: auto;//box-shadow: 0 0 3px 2px rgba(200, 191, 191, 0.939);//overflow: hidden ;

                // .table{
                //     padding:1ch;                    //box-shadow: 0 0 1px lightgray; //box-shadow: 0 0 3px 2px azure; //border:1px solid black;                    
                // }   
        
            }            
    
        }
        .buttProf {
            font-size: clamp(0.67rem, .7vw, 1.1rem);
            width:clamp(18%, 15%, 10%);
            box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.315);                    //font-size:smaller;    //font-weight: 800;    //font-style: italic;
            font-family: Arial;
            color:darkblue;
            background-image: linear-gradient(115deg, #c6a0aaad 40%, #eba782ac);
            text-shadow: 1px 1px #9c9999;                    //font-size: 'clamp(0.75rem, .8vw, 1.1rem)';
            cursor: pointer;                    //:active{box-shadow: 5px 5px 7px 8px #3b403a72}
            
            &:hover{
                font-style:italic;  
                background-color: pink;// font-size:larger;// font-style: italic;// 
                box-shadow: 1px 1px 3px 3px rgba(44, 71, 40, 0.756);   
            }
        }
        .tableCellName{
            font-size: clamp(0.7rem, .88vw, 1.1rem);
            font-weight: 600;
            font-family: Arial, Helvetica, sans-serif;
            border:1px solid lightgray;
            color:#2d187be0;

        }
        
        .tableCellVal{
            font-size: clamp(0.65rem, .82vw, 1rem);
            font-weight: 400;
            font-family: Arial, Helvetica, sans-serif;
            border:1px solid lightgray;
        
        
        }
        .caption{
            font-size: clamp(0.8rem, .95vw, 1.2rem);
            font-weight: 600;
            font-family: Arial, Helvetica, sans-serif;
            border:1px solid lightgray;
            color:#2d187be0;
            
        }

//     }    

// }
