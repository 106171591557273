// .list{
//     display: flex;

//     .listContainer{
//         flex: 8;
//         width:100vw;
//         overflow: hidden;
//         background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .93)) ;
//         background-repeat: no-repeat;//        background-size:auto;
//         background-position:bottom right;
//         background-blend-mode:lighten;  
        
        //height:100vh;
        //background-color: aqua;
        // background-image:  url('../../resource/images/autom8-logo-white.png'),
        // linear-gradient(to bottom,
        //  rgba(0, 0, 255, 0.206),
        //  rgba(150, 150, 96, 0.251)), ;
        // background-repeat: no-repeat;
        // //background-size: cover;
        // background-position: bottom right;
        //opacity:20%;
        //  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        //  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.724);
        // border: '2px solid red';

         .tableContainer {
        //     display:flex;
        //     //height:100vh;
        //     //  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        //     //  box-shadow: 2px 2px 6px 1px rgba(112, 107, 107, 0.613);
        //     // padding: 0px;
        //     //margin-left: 2px;
        //     //margin-top: 1px;
        //     //height:100%;//80vh;
        //     //width:100%;
        //     overflow: auto;
        //     // align-items: flex-start;
        //     justify-content: flex-start;
        //     // width: 50hw;
              ///font-size: 10px;

            display: flex;
            align-items: center;         
            justify-content:space-evenly;
            margin: 0 auto;
            overflow: auto;
            background-image: url('../../resource/images/juxaroo.jpg'), 
            linear-gradient(to bottom,rgba(244, 251, 244, 0.96), rgba(225, 236, 225, 0.89)) ;
            background-repeat: no-repeat;
            background-size:cover;
            background-position:center;
            background-blend-mode:color-dodge;
            //     background-image: url('../../resource/images/juxaroo.jpg'), 
            //         linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95)) ;
                //background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95)) ;
            //     background-repeat: no-repeat;       
            //     //background-size:auto;
            //     background-size:cover;
            //     background-position:center;
            //     background-blend-mode:soft-light;
            //height:100%;
            width:100vw;



       }   

//     }

// }