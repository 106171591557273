// app.scss module holds all global variables use ( @use '../../app.scss'; ) in target file:

$font: Arial, Helvetica, sans-serif;

$black: #333;
$green:green;
$red:red;

$small:small;
$medium:medium;
$large:large;



// this selector (%selector_name) is extended by ( @extend %message-shared;) in any file using @use 'file_name'

%message-shared {
    font-family: $font;
    font-size: $medium;
    border: 1px solid #bbb;
    box-shadow:1px 1px 1px #ccc;
    background-color: #eeee;
    padding: 0px 3px 0px 3px;
    color: $black;
    width: clamp(50%, 78%, 100%); 
    
}

%toolbelt {
    box-sizing: border-box;
    border: .5px rgba(#000, .12) solid;
    padding: 3px 3px 3px 0;
    width: 100%;
    &:hover { border: 1px rgba(#290ed6,1) solid; }
  }


.app{
    //display: flex;
    //box-sizing: border-box;
    //margin: 10;
    //padding: 1;
    width:100%;
    height: 100vh;

    .appContainer{        
        display: flex;
        flex: 8;
    }
}