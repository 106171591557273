// .home{
//     display: flex;
    
//     .homeContainer{ 
//       flex: 8;
//       width:100vw;
//       overflow: hidden ;
//       background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95)) ;
//       background-repeat: no-repeat;
//       //background-size:auto;
//       background-position:bottom right;
//       background-blend-mode:soft-light;

      .main{
         background: url('../../resource/images/juxaroo.jpg'), 
            linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95)),
            radial-gradient(circle, rgb(255 255 255 / 100%) 0%, rgb(52, 48, 110) 28%),
            radial-gradient(closest-side, #3f87a6, #ebf8e1, #f69d3c),
            radial-gradient(circle at 100%, #333, #333 50%, #eee 75%, #333 75%),
            radial-gradient(ellipse at top, #e66465, transparent),
            //radial-gradient(ellipse at bottom, #4d9f0c, transparent),
            radial-gradient(cyan 5%, transparent 70%, salmon 90%);

         background-repeat: no-repeat;       
         background-size:cover;
         background-position:center center;
         background-blend-mode:soft-light;
         max-width: 100%;
         font-family: Arial, Helvetica, sans-serif;
      }


      .juxaroo{
         display: flex;
         margin: 0 auto;
         background-image: url('../../resource/images/juxaroo.jpg'), 
         radial-gradient(cyan 5%, transparent 70%, salmon 90%);
         //conic-gradient(red, orange, yellow, green, blue);
         //conic-gradient(red 0deg, orange 90deg, yellow 180deg, green 270deg, blue 360deg);
         //background-image: url('../../resource/images/juxaroo.jpg'), linear-gradient(to bottom,rgba(22, 14, 106, 0.41), rgba(22, 14, 106, .1)) ;
         background-repeat: no-repeat;       
         background-size:cover;
         background-position:center center;
         background-blend-mode:soft-light;
         //background-position: calc(50% + 10px) calc(100vh - 700px);
         height:100vh;
         width:100vw;
         max-width: 100%;
         max-height: 100%;
         //width: calc(100% - 100px);
         font-family: Arial, Helvetica, sans-serif;
         
      }

      .btn1 {
         width:10ch;
         height:3ch;
         box-shadow: 2px 2px 3px 1px rgba(86, 82, 82, 0.897);         //font-size: 16px;
         font-size: calc(10px + (20 - 17) * (100vw - 400px) / (800 - 400));
         background-position: calc(50% + 10px) calc(100vh - 200px);         //padding: calc(20px + (40 - 20) * ((100vw - 400px) / (1200 - 400)));         //margin: calc(10px + (20 - 10) * ((100vw - 400px) / (800 - 400)));
         
         font-weight: 600;
         font-family: Arial, Helvetica, sans-serif;
         color:darkblue;
         background-image: linear-gradient(115deg, #a2989bad 40%, #eba782ac);         //background-image: url('../../resource/images/juxaroo.jpg'), linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95)) ;
         transition: width .5s, height 2s;
         //transition: width calc(0.3s + 0.2s * ((100vw - 400px) / (800 - 400))) ;
         cursor: pointer;
     
         .btn1:active{ box-shadow: 5px 5px 7px 8px gray; }
     
     }

       .mainContainer{
         display: flex;
         flex-direction: column;
         align-items: center;         
         justify-content:space-evenly;
         margin: 0 auto;
         overflow: auto;
         background-image: url('../../resource/images/juxaroo.jpg'), 
         linear-gradient(to bottom,rgba(244, 251, 244, 0.96), rgba(225, 236, 225, 0.89)) ;
         background-repeat: no-repeat;
         background-size:cover;
         background-position:center;
         background-blend-mode:color-dodge;
           height:100vh;
           width:100vw;
         // max-width: 100%;
         // max-height: 100%;
         //position: relative;

         .padding_5{
            padding: 4px;
         }
      
         .title{
            //-webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            display: flex;
            align-items: center;
            height: 50vh;

            .gradientText{

               // width:50%; 
               // height: 50%; 
               display: flex; 
               // margin-left: 20ch;
               // justify-self: center;
               align-self: center;
               font-size: 30px;
               font-weight: 800;
               font-family: Arial, Helvetica, sans-serif;
         
               background-image: linear-gradient(180deg, red, blue), radial-gradient(cyan 5%, transparent 70%, salmon 90%);
               background-clip: text;
               color: transparent;
               transition: width 5s, height 2s;

            }

         }


       }  
//     }

// }
// .borders{

//    -webkit-box-shadow: 6px 4px 5px 2px rgba(144, 124, 124, 0.775);
//    box-shadow: 2px 2px 3px 5px rgba(158, 146, 146, 0.939);
//    margin-left: 2px;
//    background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgb(244, 251, 244), rgba(220, 240, 223, 0.823)), ;
//    background-repeat: no-repeat;
//    //background-size: cover;
//    background-position: bottom right;
//    background-blend-mode: soft-light;
//  }