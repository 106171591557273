// .sideBar{
//     display: flex;    
    //flex-direction:row;   
    //background-color: rgb(235, 227, 227);

.navBar{
        // flex: 8;
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        justify-content: flex-start;            
        overflow: auto;
        background-image: url('../../resource/images/juxaroo.jpg'),linear-gradient(to bottom,rgba(244, 251, 244, 0.96), rgba(225, 236, 225, 0.89)) ;
        background-repeat: no-repeat;
        background-size:cover;
        background-position:center;
        background-blend-mode:color-dodge;
        height:100vh;

        .aboutScr{    
            display: flex;
            flex-direction:column;
            align-items:center;
            justify-content: center;            //margin: 5ch;
            overflow: auto ;            //height: auto;            
            width:clamp(300px, 70%, 1100px); 
            padding:20px;    
            gap:30px;            

            // .newItem{
            //     display: flex;
            //     flex-direction:column;
            //     align-items:center;
            //     justify-content: center;
            //     //margin: 10px;
            //     // width:fit-content;
                
            // }

            .aboutTable{
                 font-size:1em;                
                // border:1px solid red;
                 box-shadow: 0 0 2px  lightgray; 
                 
                
                .padding_10A{
                    padding-top: 10px;
                    padding-bottom: 15px;
                    font-size: 1.15em;
                    font-weight: 800;
                    //font-style: italic;
        
                    color: black;

                }


                .padding_5A{
                    padding: 10px;
                    font-size:1em;
                    font-weight: 400;
                    //color: black;
                }

                .hrA {
                    margin-left: 1px;
                    margin-bottom: 40px;
                    margin-top: 40px;
                    height: 7px;
                    width: 400px;
                    border: 4px solid rgb(198, 189, 189);
                    background-color: brown;
                }
  
                                                        //    .gradientText{
                                                        //       width:40%; 
                                                        //       height: 30%;  
                                                        //       justify-self: left;
                                                        //       align-self: center;
                                                        //       font-size: 24px;
                                                        //       font-weight: 800;
                                                        //       background-image: linear-gradient(120deg, red, blue);
                                                        //       background-clip: text;
                                                        //       color: transparent;
                                                        //       transition: width 3s, height 2s;

                                                        //    }
                                                        //    .gradientText:hover {
                                                        //       height:50%;
                                                        //       width: 80%;
                                                        //     }

            }


            meter {
                /* Reset the default appearance for -webkit- only */
                //-webkit-appearance: none;
                width: 300px;
              }
              
             meter::-webkit-meter-bar {
                background: #eee;
                box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2) inset;
                border-radius: 3px;
              }
            
             

        
        }
    
}

.butt {
    font-size: clamp(0.65rem, .7vw, .9rem);
    width:clamp(18%, 15%, 10%);
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.315);                    //font-size:smaller;    //font-weight: 800;    //font-style: italic;
    font-family: Arial;
    color:darkblue;
    background-image: linear-gradient(115deg, #c6a0aaad 40%, #eba782ac);
    text-shadow: 1px 1px #9c9999;                    //font-size: 'clamp(0.75rem, .8vw, 1.1rem)';
    cursor: pointer;                    //:active{box-shadow: 5px 5px 7px 8px #3b403a72}
    
    &:hover{
        font-style:italic;  
        background-color: pink;// font-size:larger;// font-style: italic;// 
        box-shadow: 1px 1px 3px 3px rgba(44, 71, 40, 0.756);   
    }
}
.tableCellName{
    font-size: clamp(0.65rem, .83vw, 1rem);
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    border:1px solid lightgray;
    color:#2d187be0;

}
.tableCellVal{
    font-size: clamp(0.6rem, .8vw, .9rem);
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
    border:1px solid lightgray;


}
.caption{
    font-size: clamp(0.7rem, .85vw, 1.1rem);
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    border:1px solid lightgray;
    color:#2d187be0;
    padding: 2;
    background-color:lightgray;
    

}
// }