
// .batchSideBar{
//     display: flex;

    .batchNavBar{ 
      //flex: 8;
      // width:100vw;
      overflow:auto;
      background-image: url('../../resource/images/juxaroo.jpg'), linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95)) ;
      background-repeat: no-repeat;
      background-size:cover;
      background-position:center;
      background-blend-mode:soft-light;
      //margin-top:630px;
      height:100vh;
      

       .batchScreen{
          display: flex;
          flex-direction:column;  
          overflow:auto;
          height:100vh;
          //margin-top: 5ch;

            // width:100vw;
            // -webkit-box-shadow: 0px 1px 4px 2px rgba(131, 130, 130, 0.388);
            // box-shadow: 0px 0px 2px 2px rgba(131, 130, 130, 0.388);
            // flex-direction: column;
            // align-items: flex-start;
            // justify-content: flex-start;
            // //background-color:rgba(69, 140, 69, 0.638);
            
            // background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95));
            // background-repeat: no-repeat;
            // background-size: cover;
            // background-position: bottom right;
            // background-blend-mode: soft-light;
        
            // .container1{
    
            //   .custom-shape-divider-top {
            //       position: absolute;
            //       top: 1;
            //       left: 0;
            //       width: 100%;
            //       overflow: hidden;
            //       line-height: 1;
            //       transform: rotate(180deg);

            //     //   svg{
            //     //     position: relative;
            //     //     display: block;
            //     //     width: calc(133% + 1.3px);
            //     //     height: 20px;
            //     //     border:'1px solid red'
            //     // }

            //   }
            //   .shape-fill {
            //     fill: #E74E4E;
            //   }
              
      
            // }

       }  
    }

//}

.custom-file-input {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;

}
.custom-file-input::before {
content: 'Select File for the batch upload';
color: black;
display: inline-block;
background: -webkit-linear-gradient(top, #f9f9f9, #caaeae);
border: 1px solid #d79494e4;
border-radius: 5px;
padding: 7px 10px;
outline: none;
white-space: nowrap;
//-webkit-user-select: none;
cursor: pointer;
text-shadow: 1px 1px #f4ebeb;
font-weight: 600;
font-size: 11;
}
.custom-file-input:hover::before {
border-color: black;
color: dodgerblue;
}
.custom-file-input:active {
outline: 0;
}
.custom-file-input:active::before {
background: -webkit-linear-gradient(top, #fedede, #f9f9f9); 
}

